import {
  PromoError,
  Promotion as PromotionResponse,
} from "~/lib/promotions/interfaces";
import {
  CartAddress,
  CartProduct,
  CartPromo,
  Catalog,
  Discount,
  Hardware,
  Plan,
} from "~/store/interfaces";
import CartMath from "../CartMath";
import { Characteristic, Characteristics } from "../client/pq/ProductQualificationClientInterface";

const STANDARD_SPEEDS = [12, 25, 50, 100, 250, 1000];

export default class PromotionWrapper {
  private promos: PromotionResponse[];

  constructor(promotions: PromotionResponse[]) {
    this.promos = promotions;
  }

  public filteredPromos(
    promoDiscounts: Catalog[],
    address: CartAddress,
    catalog?: string,
    characteristics?: Characteristics
  ): CartPromo[] {
    const formatPromos: CartPromo[] = [];

    this.promos.forEach((a) => {
      const tempPromo: CartPromo = {
        code: "",
        description: a.description,
        discounts: [],
        carriers: [],
        developments: [],
        suburbs: [],
        atl: false,
        id: a.id,
        validFor: a.validFor,
        error: "",
        isValid: true,
        minContract: null,
        minDownload: null,
        maxDownload: null,
        type: "promo",
        plans: [],
        hiddenPlans: [],
        partners: [],
        ntdLocation: null,
        velocityStatus: [],
        catalogGroup: []
      };

      a.pattern.forEach((b) => {
        tempPromo.code = b.name;

        b.criteriaGroup.forEach((c) => {
          if (c.groupName === "carrier as condition") {
            c.criteria.forEach((d) => {
              tempPromo.carriers.push(d.criteriaValue);
            });
          }

          if (c.groupName === "plan speed as condition") {
            c.criteria.forEach((d) => {
              if (d.criteriaPara === "downloadSpeed") {
                // eslint-disable-next-line radix
                tempPromo.minDownload = parseInt(d.criteriaValue);
              } else if (d.criteriaPara === "maxDownloadSpeed") {
                // eslint-disable-next-line radix
                tempPromo.maxDownload = parseInt(d.criteriaValue);
              }
            });
          }

          if (c.groupName === "contract length as condition") {
            c.criteria.forEach((d) => {
              // eslint-disable-next-line radix
              tempPromo.minContract = parseInt(d.criteriaValue);
            });
          }

          if (c.groupName === "suburb as condition") {
            c.criteria.forEach((d) => {
              tempPromo.suburbs.push(
                d.criteriaValue.normalize("NFC").toUpperCase()
              );
            });
          }

          if (c.groupName === "development as condition") {
            c.criteria.forEach((d) => {
              tempPromo.developments.push(
                d.criteriaValue.normalize("NFC").toUpperCase()
              );
            });
          }

          if (c.groupName === "display as condition") {
            c.criteria.forEach(() => {
              tempPromo.atl = true;
            });
          }

          if (c.groupName === "plan as condition") {
            c.criteria.forEach((d) => {
              tempPromo.plans.push(d.criteriaValue);
            });
          }

          if (c.groupName === "hidden plan as condition") {
            c.criteria.forEach((d) => {
              tempPromo.hiddenPlans.push(d.criteriaValue);
            });
          }

          if (c.groupName === "partners as condition") {
            c.criteria.forEach((d) => {
              tempPromo.partners.push(d.criteriaValue);
            });
          }

          if (c.groupName === "ntd location as condition") {
            c.criteria.forEach((d) => {
              tempPromo.ntdLocation = d.criteriaValue;
            });
          }

          if (c.groupName === "velocity status as condition") {
            c.criteria.forEach((d) => {
              tempPromo.velocityStatus.push(d.criteriaValue);
            });
          }

          if (c.groupName === "catalog group as condition") {
            c.criteria.forEach((d) => {
              tempPromo.catalogGroup.push(d.criteriaValue);
            });
          }

          if (c.groupName === "should display as condition") {            
            tempPromo.shouldDisplay = true;
          }
        });

        let filteredActions = [];
        if (b.action) {
          filteredActions = b.action.map((item) => {
            const obj = promoDiscounts.find(
              (o) => o.id === item.actionObjectId
            );
            return { ...item, ...obj };
          });
        }
        tempPromo.discounts = filteredActions;
      });

      let promoIsValid = true;

      const startDate = new Date(tempPromo.validFor.startDateTime);
      const endDate = new Date(tempPromo.validFor.endDateTime);

      const now = new Date();
      if (now < startDate || now > endDate) {
        promoIsValid = false;
      }

      if (characteristics !== undefined && tempPromo.code === 'ALAND' && characteristics.has('used_aland_promo') && characteristics.get('used_aland_promo').value === true) {
        promoIsValid = false;
      }

      if (!tempPromo.carriers.includes(address.network.carrierCode)) {
        promoIsValid = false;
      }

      if (catalog && catalog !== 'Residential' && (!tempPromo.catalogGroup.includes(catalog) || catalog.includes('Business')) ) {
        promoIsValid = false
      }

      if (promoIsValid) {
        formatPromos.push(tempPromo);
      }
    });
    return formatPromos;
  }

  public getSuggestedPromo(
    validPromos: CartPromo[],
    partner: string,
    address: CartAddress,
    forcedPromo: string,
    technology?: string
  ): CartPromo {
    let primaryPromo: CartPromo;

    let tempPromos: CartPromo[] = validPromos;

    tempPromos = validPromos.filter((p) => {
      if (technology && p.carriers.includes("SAT") && technology === "SAT") {
        return true;
      }

      if (technology && p.carriers.includes("SMP") && technology === "SMP") {
        return true;
      }

      if (p.carriers.includes(address.carrier)) {
        if (technology && (technology === "SMP" || technology === "SAT")) {
          return false;
        }
        return true;
      }

      return false;
    });

    if (partner) {
      const partnerPromos = tempPromos.filter((pf) =>
        pf.partners.includes(partner)
      );

      if (partnerPromos.length > 0) {
        tempPromos = partnerPromos;
      }
    }

    if (!partner) {
      tempPromos = tempPromos.filter((pf) => pf.partners.length === 0);
    }

    if (tempPromos.length) {
      // if promo is atl
      tempPromos.map(async (pp) => {
        if (pp.atl) {
          primaryPromo = pp;
        }
      });

      tempPromos.map(async (pp) => {
        if (
          (pp.developments.length &&
            address &&
            address.developmentName &&
            pp.developments.includes(address.developmentName)) &&
            pp.shouldDisplay ||
          (!pp.developments.length &&
            pp.suburbs.length &&
            pp.suburbs.includes(address.suburb)) && 
            pp.shouldDisplay
        ) {
          primaryPromo = pp;
        }
      });

      // if NTD location matches
      tempPromos.map(async (promo) => {
        if (promo.ntdLocation && promo.ntdLocation === address.ntdLocation) {
          if (
            promo.velocityStatus.length &&
            promo.velocityStatus.includes(address.velocityStatus)
          ) {
            primaryPromo = promo;
          }
        }
      });

      // if forced promo exists
      tempPromos.map(async (pp) => {
        if (pp.code === forcedPromo) {
          primaryPromo = pp;
        }
      });
    }

    return primaryPromo;
  }

  public getPromoMinDownload(
    primaryCode: string,
    validPromos: CartPromo[]
  ): number {
    const tempDataDownload = validPromos.filter(
      (aa) => aa.code === primaryCode
    );

    tempDataDownload.sort((a, b) => a.minDownload - b.minDownload);

    return tempDataDownload[0].minDownload;
  }

  public getPromoMinContract(
    primaryCode: string,
    validPromos: CartPromo[]
  ): number {
    const tempDataContract = validPromos.filter(
      (aa) => aa.code === primaryCode
    );

    tempDataContract.sort((a, b) => a.minContract - b.minContract);

    if (primaryCode === 'UNITIONE') {
      return 12;
    }

    return tempDataContract[0].minContract;
  }

  public getPromo(validPromos: CartPromo[], contract: CartProduct): CartPromo
  {
    let filteredPromos = validPromos;
    filteredPromos = validPromos.filter(
      (fp) =>
        contract &&
        fp.minContract === contract.term ||
        (contract.term === 0 && !fp.minContract) ||
        (fp.minContract === null && validPromos.length === 1)
    );

    return filteredPromos[0];
  }

  public getPromoFromGroup(
    validPromos: CartPromo[],
    plan: CartProduct,
    contract: CartProduct,
    address: CartAddress,
    partner: string
  ): CartPromo {
    let filteredPromos = validPromos;
    const contractTerms = [];

    filteredPromos.map((fp) => {
      if (!contractTerms.includes(fp.minContract)) {
        contractTerms.push(fp.minContract)
      }
      return true
    })

    if (contractTerms.length > 1) {

      filteredPromos = filteredPromos.filter(
        (fp) =>
          contract &&
          fp.minContract === contract.term ||
          (contract.term === 0 && !fp.minContract)
      );
    }

    if (!partner) {
      filteredPromos = filteredPromos.filter((fp) => !fp.partners.length);
    } else {
      const partnerPromos = filteredPromos.filter((fp) =>
        fp.partners.includes(partner)
      );

      if (partnerPromos.length > 0) {
        filteredPromos = partnerPromos;
      }
    }

    if (address.ntdLocation && address.velocityStatus) {
      const ntdPromos = filteredPromos.filter(
        (fp) =>
          fp.velocityStatus.includes(address.velocityStatus) &&
          fp.ntdLocation === address.ntdLocation
      );

      if (ntdPromos.length > 0) {
        filteredPromos = ntdPromos;
      }
    }
    if (filteredPromos[0] && filteredPromos[0].plans && filteredPromos[0].plans.length > 0) {
      filteredPromos = filteredPromos.filter((fp) =>
      plan.symbillId && fp.plans.includes(plan.symbillId.toString())
      );
    }

    return filteredPromos[0];
  }

  public getPromoErrors(
    activePromo: CartPromo,
    address: CartAddress,
    plan: Plan,
    contract: CartProduct,
    characteristics?: Characteristics
  ): PromoError[] {
    const errors: PromoError[] = [];
    if (activePromo.suburbs.length > 0) {
      if (!activePromo.suburbs.includes(address.suburb)) {
        errors.push({
          code: "suburbs",
          description: "The promo code is not valid in your suburb.",
        });
      }
    }

    if (activePromo.developments.length && address.developmentName) {
      if (
        !activePromo.developments.includes(
          address.developmentName.normalize("NFC").toUpperCase()
        )
      ) {
        errors.push({
          code: "developmentName",
          description: "The promo code is not valid at your address.",
        });
      }
    }
    
    if (characteristics !== undefined && activePromo.code === 'ALAND' && characteristics.has('used_aland_promo') && characteristics.get('used_aland_promo').value === true) {
      
      errors.push({
        code: "developmentName",
        description: "The promo code is not valid at your address.",
      });
    }

    if (plan && plan.download && plan.download < activePromo.minDownload) {
      errors.push({
        code: "minDownload",
        description: `Minimum download speed of promo is ${activePromo.minDownload} Mbps.`,
      });
    }

    if (plan && plan.download && plan.download > activePromo.maxDownload) {
      errors.push({
        code: "maxDownload",
        description: `Maximum download speed of promo is ${activePromo.maxDownload} Mbps.`,
      });
    }

    if (contract && contract.term < activePromo.minContract) {
      errors.push({
        code: "minContract",
        description: `Minimum contract term of promo is ${activePromo.minContract} months.`,
      });
    }

    if (
      activePromo.ntdLocation &&
      address.ntdLocation &&
      activePromo.ntdLocation === address.ntdLocation &&
      !activePromo.velocityStatus.includes(address.velocityStatus)
    ) {
      errors.push({
        code: "ntdLocation",
        description: `The promo code is not valid at your address.`,
      });
    }

    return errors;
  }

  public getOngoingSavings(
    activePromo: CartPromo,
    cart: CartProduct[]
  ): number {
    let ongoingSavings = 0;

    activePromo.discounts.forEach((discount) => {
      if (discount.name === "PERCENT") {
        const totalPercentage = discount.price > 100 ? 100 : discount.price;
        ongoingSavings +=
          (totalPercentage / 100) *
          cart.filter((p) => p && p.type === "plan").find((e) => !!e).price;
      }

      if (discount.name === "RECURRING" && !discount.actionStart) {
        ongoingSavings += discount.price;
      }
    });

    return ongoingSavings;
  }

  public getOngoingSavingsAfter(
    activePromo: CartPromo,
    cart: CartProduct[]
  ): number {
    let ongoingSavings = 0;

    activePromo.discounts.forEach((discount) => {
      if (discount.name === "PERCENT-AFTER") {
        ongoingSavings += parseFloat(
          (
            (discount.price / 100) *
            this.getNewPlanPrice(activePromo, cart)
          ).toFixed(2)
        );
      }
    });

    return ongoingSavings;
  }

  public getOneoffSavings(
    activePromo: CartPromo,
    cart: CartProduct[],
    contract: CartProduct,
    address: CartAddress,
    filteredHardware: Hardware[]
  ): number {
    let oneOffSavings = 0;
    activePromo.discounts.forEach((discount) => {
      if (this.hasHardwareInCart(cart)) {
        const baseRouter = this.getBaseRouter(
          filteredHardware,
          discount,
          cart.find((p) => p && p.type === "hardware")
        );
        if (discount.name === "FREE-ROUTER") {
          if (baseRouter) {
            oneOffSavings += CartMath.getFreeRouterDiscount(
              cart.find((p) => p && p.type === "hardware"),
              baseRouter,
              contract.term,
              address.network.carrierCode
            );
          }
        }

        if (discount.name === "ROUTER-DISCOUNT") {
          if (
            (discount.actionApplicableTo.length &&
              discount.actionApplicableTo.includes(
                cart.find((p) => p && p.type === "hardware").code
              )) ||
            discount.actionApplicableTo.find((aa) =>
              cart.find((p) => p && p.type === "hardware").code.includes(aa)
            )
          ) {
            oneOffSavings += CartMath.getBaseRouterDiscount(
              cart.find((p) => p && p.type === "hardware"),
              baseRouter,
              contract.term,
              address.network.carrierCode,
              discount.price
            );
          }
        }
      }

      if (discount.name === "FREE-ACTIVATION") {
        oneOffSavings += cart.find((p) => p && p.type === "activationFee")
          ? cart.find((p) => p && p.type === "activationFee").price
          : 0;
      }

      if (discount.name === "PERCENT") {
        const totalPercentage = discount.price > 100 ? 100 : discount.price;
        oneOffSavings -=
          (totalPercentage / 100) *
          cart.find((p) => p && p.type === "plan").price;
      }

      if (discount.name === "RECURRING" && !discount.actionStart) {
        oneOffSavings -= discount.price;
      }

      if (discount.name === "ONE-OFF") {
        oneOffSavings += cart.find((p) => p && p.type === "plan") ? cart.find((p) => p && p.type === "plan").price : 0;
      }

      if (discount.name === "MONTH-OFF") {
        oneOffSavings +=
          cart.find((p) => p && p.type === "plan").price * discount.term;
      }

      if (discount.name === "YEAR-OFF") {
        const totalPercentage = discount.price > 100 ? 100 : discount.price;
        oneOffSavings -=
          (totalPercentage / 100) *
          cart.find((p) => p && p.type === "plan").price *
          12;
      }

      if (discount.name === "DELAYED-NDC" || discount.name === "FREE-NDC") {
        oneOffSavings += cart.find((p) => p && p.type === "ndc")
          ? cart.find((p) => p && p.type === "ndc").price
          : 0;
      }

      if (discount.name === "HALF-NDC") {
        oneOffSavings += cart.find((p) => p && p.type === "ndc")
          ? discount.price * (-1)
          : 0;
      }
    });

    return oneOffSavings;
  }

  public getOneoffSavingsAfter(
    filteredHardware: Hardware[],
    cart: CartProduct[],
    activePromo: CartPromo,
    address: CartAddress
  ): number {
    let oneOffSavings = 0;
    activePromo.discounts.forEach((discount) => {
      if (
        discount.code === "ROUTER-DISCOUNT-AFTER" &&
        this.hasHardwareInCart(cart) &&
        filteredHardware.length
      ) {
        if (
          (discount.actionApplicableTo.length &&
            discount.actionApplicableTo.includes(
              cart.find((p) => p.type === "hardware").code
            )) ||
          discount.actionApplicableTo.find(
            (d) => d === filteredHardware[0].code
          ) ||
          !discount.actionApplicableTo.length
        ) {
          const newRouterPrice = this.getNewRouterPrice(
            activePromo,
            cart,
            filteredHardware,
            cart.find((ct) => ct.type === "activationFee"),
            address
          );
          oneOffSavings -= parseFloat(
            ((discount.price / 100) * newRouterPrice).toFixed(2)
          );
        }
      }
    });

    return oneOffSavings;
  }

  public getRouterDiscount(
    filteredHardware: Hardware[],
    contract: CartProduct,
    routerDiscount: Discount,
    router: Hardware,
    address: CartAddress,
    cart: CartProduct[]
  ): number {
    let tempDiscounts = 0;
    if (router && routerDiscount) {
      if (
        routerDiscount.actionApplicableTo &&
        routerDiscount.actionApplicableTo.includes(router.code)
      ) {
        tempDiscounts += router.price * (routerDiscount.price / -100);
      }
      // if discount is on base router only
      else if (
        filteredHardware.length > 1 &&
        routerDiscount.actionApplicableTo &&
        routerDiscount.actionApplicableTo.find((aa) => router.code.includes(aa))
      ) {
        const baseRouter = this.getBaseRouter(
          filteredHardware,
          routerDiscount,
          router
        );
        tempDiscounts += CartMath.getBaseRouterDiscount(
          router,
          baseRouter,
          contract.term,
          address.network.carrierCode,
          routerDiscount.price
        );
      }
    }
    if (
      this.hasHardwareInCart(cart) &&
      cart.find((p) => p && p.name === "ROUTER-DISCOUNT-AFTER")
    ) {
      const newRouterPrice = router.price - tempDiscounts;
      const rd =
        cart.find((p) => p && p.name === "ROUTER-DISCOUNT-AFTER").price / 100;
      const fd = parseFloat((newRouterPrice * rd).toFixed(2));
      return Math.abs(fd);
    }

    return tempDiscounts;
  }

  public addPromosToCart(
    cart: CartProduct[],
    activePromo: CartPromo,
    allHardware: Hardware[],
    serviceClass?: string
  ): CartProduct[] {
    const newCart = cart;
    let addonHardware = null;
    const hardwareCart = cart.find(h => h.type === 'hardware');

    activePromo.discounts.map((dis) => {
      // add router promo if there is a router added
      if (!this.hasHardwareInCart(cart) && dis.name && dis.name.includes("ROUTER")) {
        return false;
      }
      // dont add router promo if not in applicableto
      if (
        this.hasHardwareInCart(cart) &&
        dis.name &&
        dis.name.includes("ROUTER") &&
        dis.actionApplicableTo.length > 0 &&
        hardwareCart
      ) {
        if (!(dis.actionApplicableTo.includes(hardwareCart.code) || 
          dis.actionApplicableTo.find(aa => hardwareCart.code.includes(aa)))) {
            return false
        }
      }
      // dont add ndc promo if no ndc and if not serviceClass 1
      if (!this.hasNdcInCart(cart) && dis.name && dis.name.includes("NDC") && serviceClass !== "1") {
        return false;
      }
      newCart.push(dis);
      return dis;
    });

    // check for addons
    activePromo.discounts.map((dis) => {
      if (dis.name === "UNLOCK-HARDWARE") {
        addonHardware = allHardware.find((ch) =>
          dis.actionApplicableTo.includes(ch.code)
        );

        newCart.push(addonHardware);
      }
      return dis;
    });

    return newCart;
  }

  public getCartWithoutPromos(cart: CartProduct[]): CartProduct[] {
    // bonus hardware
    const bonusHardware = ["SONOS"];
    const newCart = [];
    cart.map((pr) => {
      if (pr && pr.type === "promotion") {
        return pr;
      }
      if (pr && pr.type === "hardware" && bonusHardware.includes(pr.code)) {
        return pr;
      }
      newCart.push(pr);
      return pr;
    });

    return newCart;
  }

  private hasHardwareInCart(cart: CartProduct[]): boolean {
    return (cart.filter((p) => p && p.type === "hardware").length > 0);
  }

  private hasNdcInCart(cart: CartProduct[]): boolean {
    return (cart.filter((p) => p && p.type === "ndc").length > 0);
  }

  public getBaseRouter (
    filteredHardware: Hardware[],
    discount: Discount,
    hardware: CartProduct
  ): Hardware {
    if (discount.actionApplicableTo && discount.actionApplicableTo.length > 0) {
      return filteredHardware.find((fw) =>
        discount.actionApplicableTo.find(
          (aa) => aa === fw.code && hardware.code.includes(aa)
        )
      );
    }

    return filteredHardware[0];
  }

  /**
   * Takes a promo code, the promotions catalog, and the current address of the cart
   * and produces a map which represents the discount given to each symbill plan ID instead of speed. This will be flexible especially with business plans that have the same download speed. For now this will still map to speeds
   *
   * For example there may be 3 DISCOVERBETTER promos, and we will find all the plan discounts
   * across them and put them all into a single Map of the form:
   *
   * [
   *   [50, 10],
   *   [100, 20],
   *   [250, 30],
   *   [1000, 30],
   * ]
   *
   * @param promoCode
   * @param promotions
   * @param plans
   */
  public discountsBySpeed(promoCode: string, promotions: CartPromo[], plans: Plan[]): Map<string, { discount: number, months: number }> {
    const speedMap = new Map<string, { discount: number, months: number }>()

    const promosForCode = promotions.filter(promo => promo.code === promoCode)

    plans.forEach(pl => {
      promosForCode.forEach(promo => {
        if ((promo.plans.length > 0 && promo.plans.includes(pl.symbillId)) || (!promo.plans.length && pl.download >= promo.minDownload && pl.download <= promo.maxDownload)) {
          const recurringDiscount = promo.discounts.find(discount => discount.name === 'RECURRING')

          if (recurringDiscount) {
            speedMap.set(pl.symbillId, {
              discount: Math.abs(recurringDiscount.price),
              months: recurringDiscount.actionDuration
            })
          }
        }
      })
    })

    return speedMap
  }

  private getNewPlanPrice(activePromo: CartPromo, cart: CartProduct[]): number {
    let newPlanPrice = cart.find((p) => p && p.type === "plan").price;
    activePromo.discounts.forEach((discount) => {
      if (discount.name === "PERCENT") {
        const totalPercentage = discount.price > 100 ? 100 : discount.price;
        newPlanPrice += (totalPercentage / 100) * newPlanPrice;
      } else if (discount.name === "RECURRING" && !discount.actionStart) {
        newPlanPrice += discount.price;
      }
    });

    return newPlanPrice;
  }

  private getNewRouterPrice(
    activePromo: CartPromo,
    cart: CartProduct[],
    filteredHardware: Hardware[],
    contract: CartProduct,
    address: CartAddress
  ): number {
    let newRouterPrice = cart.find((p) => p && p.type === "hardware").price;

    activePromo.discounts.forEach((discount) => {
      if (
        discount.code === "ROUTER-DISCOUNT-AFTER" &&
        this.hasHardwareInCart(cart)
      ) {
        const baseRouter = filteredHardware[0];
        const routerDiscount = CartMath.getBaseRouterDiscount(
          cart.find((p) => p && p.type === "hardware"),
          baseRouter,
          contract.term,
          address.network.carrierCode,
          discount.price
        );
        newRouterPrice -= routerDiscount;
      }
    });
    return newRouterPrice;
  }
}
